import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Settings.css';  // Assurez-vous que le chemin est correct

function Settings() {
  const navigate = useNavigate();
  
  // Définir les valeurs par défaut
  const defaultSettings = {
    time: 300,
    bleu: 'Phonk',
    rouge: 'Techno',
    scoreBleu: 1,
    scoreRouge: 1,
  };

  // Fonction pour vérifier la longueur du texte et renvoyer un message d'erreur si nécessaire
  const getErrorMessage = (name) => {
    return name.length > 18 ? `Le nom ne peut pas dépasser 18 caractères (actuellement : ${name.length})` : '';
  };

  // Initialisation de l'état avec les valeurs actuelles de localStorage ou les valeurs par défaut
  const [settings, setSettings] = useState({
    time: localStorage.getItem('config-time') || defaultSettings.time,
    bleu: localStorage.getItem('config-bleu') || defaultSettings.bleu,
    rouge: localStorage.getItem('config-rouge') || defaultSettings.rouge,
    scoreBleu: parseFloat(localStorage.getItem('config-scoreBleu')) || defaultSettings.scoreBleu,
    scoreRouge: parseFloat(localStorage.getItem('config-scoreRouge')) || defaultSettings.scoreRouge,
  });

  // État pour les messages d'erreur
  const [errors, setErrors] = useState({
    bleu: '',
    rouge: '',
  });

  // Gestion du changement dans le formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({
      ...settings,
      [name]: value,
    });

    // Mise à jour des messages d'erreur
    setErrors({
      ...errors,
      [name]: getErrorMessage(value),
    });
  };

  // Sauvegarde des modifications dans le localStorage et redirection
  const handleSaveAndGoBack = () => {
    if (errors.bleu || errors.rouge) {
      alert('Veuillez corriger les erreurs avant de sauvegarder.');
      return;
    }
    localStorage.setItem('config-time', settings.time);
    localStorage.setItem('config-bleu', settings.bleu);
    localStorage.setItem('config-rouge', settings.rouge);
    localStorage.setItem('config-scoreBleu', settings.scoreBleu);
    localStorage.setItem('config-scoreRouge', settings.scoreRouge);
    alert('Paramètres enregistrés avec succès!');
    navigate('/game'); // Redirection vers l'application après la sauvegarde
  };

  return (
    <div className="settings-container">
      <div className="form-wrapper">
        <h1>Paramètres de Configuration</h1>
        <label>
          Temps (secondes) :
          <input
            type="number"
            name="time"
            value={settings.time}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Nom (bleu) :
          <input
            type="text"
            name="bleu"
            value={settings.bleu}
            onChange={handleChange}
          />
        </label>
        {errors.bleu && <p className="error-message">{errors.bleu}</p>}
        <br />
        <label>
          Nom (rouge) :
          <input
            type="text"
            name="rouge"
            value={settings.rouge}
            onChange={handleChange}
          />
        </label>
        {errors.rouge && <p className="error-message">{errors.rouge}</p>}
        <br />
        <label>
          Score Phunk (bleu) :
          <input
            type="number"
            name="scoreBleu"
            value={settings.scoreBleu}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Score Techno (rouge) :
          <input
            type="number"
            name="scoreRouge"
            value={settings.scoreRouge}
            onChange={handleChange}
          />
        </label>
        <br />
        <button className="save-button" onClick={handleSaveAndGoBack}>
          Enregistrer et Retourner au Jeu
        </button>
      </div>
    </div>
  );
}

export default Settings;
