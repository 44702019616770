import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Game.css';
import MusicNoteIcon from '../../assets/music-note.png';
import RoseIcon from '../../assets/rose.png';
import HeartIcon from '../../assets/heart.png';
import ChatIcon from '../../assets/chat.png';

function App() {
  const navigate = useNavigate();
  
  // Définir les valeurs par défaut
  const defaultConfig = {
    time: 300,
    bleu: 'Phonk',
    rouge: 'Techno',
    scoreBleu: 1,
    scoreRouge: 1,
  };

  // Récupération des paramètres depuis le localStorage ou utilisation des valeurs par défaut
  const phunkScore = parseFloat(localStorage.getItem('config-scoreBleu')) || defaultConfig.scoreBleu;
  const technoScore = parseFloat(localStorage.getItem('config-scoreRouge')) || defaultConfig.scoreRouge;
  const valeur1 = localStorage.getItem('config-bleu') || defaultConfig.bleu;
  const valeur2 = localStorage.getItem('config-rouge') || defaultConfig.rouge;
  const temps = parseFloat(localStorage.getItem('config-time')) || defaultConfig.time; // Temps en seconde

  // Calcul de la corrélation
  const totalScore = phunkScore + technoScore;
  const phunkPercentage = (phunkScore / totalScore) * 100;
  const technoPercentage = (technoScore / totalScore) * 100;

  // État du compte à rebours en secondes
  const [timeLeft, setTimeLeft] = useState(temps);

  // Utilisation de useEffect pour décrémenter le temps
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Formatage du temps restant en minutes et secondes
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  // Ajout de la classe 'blinking' si le temps est inférieur ou égal à 15 secondes
  const countdownClass = timeLeft <= 15 ? 'countdown-timer blinking' : 'countdown-timer';

  return (
    <div className="container">
      {/* Bouton pour accéder aux paramètres */}
      <button className="settings-button" onClick={() => navigate('/settings')}>
        Settings
      </button>
      
      {/* Bouton pour retourner à la page d'accueil */}
      <button className="home-button" onClick={() => navigate('/')}>
        Home
      </button>

      {/* Section Phunk - Haut */}
      <div className="side phunk top">
        <h1>{valeur1}</h1>
        <div className="center-content">
          <div className="gift-container">
            <div className="pseudo-container">
              <div className="pseudo">PSEUDO</div>
              <div className="gift-icon">🎁</div>
            </div>
          </div>
        </div>
      </div>

      {/* Section Phunk - Bas */}
      <div className="side phunk bottom">
        <div className="actions">
          <div className="action-item">
            <img src={HeartIcon} alt="Heart" className="icon" />= 0.5
          </div>
          <div className="action-item">
            <img src={RoseIcon} alt="Rose" className="icon" />= 1
          </div>
        </div>
      </div>

      <h2 className="or-text">OU</h2>

      {/* Section Techno - Haut */}
      <div className="side techno top">
        <h1>{valeur2}</h1>
        <div className="center-content">
          <div className="gift-container">
            <div className="pseudo-container">
              <div className="pseudo">PSEUDO</div>
              <div className="gift-icon">🎁</div>
            </div>
          </div>
        </div>
      </div>

      {/* Section Corrélation */}
      <div className="middle">
        <div className={countdownClass}>
          {/* Affichage du compte à rebours */}
          <h2>{formatTime(timeLeft)}</h2>
        </div>
        <div className="jauge-container">
          {/* Partie Phunk */}
          <div className="jauge phunk" style={{ width: `${phunkPercentage}%` }}>
            <div className="percentage-label left">{phunkPercentage.toFixed(1)}%</div>
          </div>

          {/* Partie Techno */}
          <div className="jauge techno" style={{ width: `${technoPercentage}%` }}>
            <div className="percentage-label right">{technoPercentage.toFixed(1)}%</div>
          </div>
        </div>
      </div>

      {/* Section Techno - Bas */}
      <div className="side techno bottom">
        <div className="actions">
          <div className="action-item">
            <img src={ChatIcon} alt="Chat Bubble" className="icon" />= 0.5
          </div>
          <div className="action-item">
            <img src={MusicNoteIcon} alt="Music Note" className="icon" />= 1
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
