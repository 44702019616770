import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Preset2.css';
import MusicNoteIcon from '../../assets/music-note.png';
import RoseIcon from '../../assets/rose.png';
import HeartIcon from '../../assets/heart.png';
import ChatIcon from '../../assets/chat.png';

function Preset2() {
  const navigate = useNavigate();

  // Scores et labels (modifiable)
  const phunkScore = 1;
  const technoScore = 1;
  const valeur1 = "Phonk";
  const valeur2 = "Techno";

  const totalScore = phunkScore + technoScore;
  const phunkPercentage = (phunkScore / totalScore) * 100;
  const technoPercentage = (technoScore / totalScore) * 100;

  // Position et taille des éléments
  const [elements, setElements] = useState({
    title1: { x: 50, y: 50, width: 150, height: 50 },
    title2: { x: 300, y: 50, width: 150, height: 50 },
    pseudoPhunk: { x: 50, y: 150, width: 200, height: 150 },
    pseudoTechno: { x: 300, y: 150, width: 200, height: 150 },
    jauge: { x: 150, y: 350, width: 400, height: 50 },
    actionsPhunk: { x: 50, y: 450, width: 200, height: 150 },
    actionsTechno: { x: 300, y: 450, width: 200, height: 150 },
  });

  // Gestion du déplacement
  const handleDragStart = (e, id) => {
    const style = window.getComputedStyle(e.target, null);
    const xOffset = parseInt(style.getPropertyValue("left"), 10) - e.clientX;
    const yOffset = parseInt(style.getPropertyValue("top"), 10) - e.clientY;
    e.dataTransfer.setData("id", id);
    e.dataTransfer.setData("xOffset", xOffset);
    e.dataTransfer.setData("yOffset", yOffset);
  };

  const handleDrop = (e) => {
    const id = e.dataTransfer.getData("id");
    const xOffset = parseInt(e.dataTransfer.getData("xOffset"), 10);
    const yOffset = parseInt(e.dataTransfer.getData("yOffset"), 10);

    const newPos = {
      x: e.clientX + xOffset,
      y: e.clientY + yOffset,
    };

    setElements((prevElements) => ({
      ...prevElements,
      [id]: { ...prevElements[id], ...newPos },
    }));

    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Gestion du redimensionnement
  const handleResizeStart = (e, id) => {
    e.stopPropagation();
    const startWidth = elements[id].width;
    const startHeight = elements[id].height;
    const startX = e.clientX;
    const startY = e.clientY;

    const onMouseMove = (moveEvent) => {
      const newWidth = startWidth + (moveEvent.clientX - startX);
      const newHeight = startHeight + (moveEvent.clientY - startY);

      setElements((prevElements) => ({
        ...prevElements,
        [id]: { ...prevElements[id], width: newWidth, height: newHeight },
      }));
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  return (
    <div className="container" onDragOver={handleDragOver} onDrop={handleDrop}>
      <button className="back-button" onClick={() => navigate('/')}>
        Home
      </button>
      <button className="settings-button" onClick={() => navigate('/settings')}>
        Settings
      </button>
      
      {/* Titre 1 */}
      <div
        className="draggable"
        style={{
          left: `${elements.title1.x}px`,
          top: `${elements.title1.y}px`,
          width: `${elements.title1.width}px`,
          height: `${elements.title1.height}px`,
        }}
        draggable="true"
        onDragStart={(e) => handleDragStart(e, 'title1')}
      >
        <h1>{valeur1}</h1>
        <div
          className="resize-handle bottom-right"
          onMouseDown={(e) => handleResizeStart(e, 'title1')}
        />
      </div>

      {/* Titre 2 */}
      <div
        className="draggable"
        style={{
          left: `${elements.title2.x}px`,
          top: `${elements.title2.y}px`,
          width: `${elements.title2.width}px`,
          height: `${elements.title2.height}px`,
        }}
        draggable="true"
        onDragStart={(e) => handleDragStart(e, 'title2')}
      >
        <h1>{valeur2}</h1>
        <div
          className="resize-handle bottom-right"
          onMouseDown={(e) => handleResizeStart(e, 'title2')}
        />
      </div>

      {/* Pseudo Phunk */}
      <div
        className="draggable"
        style={{
          left: `${elements.pseudoPhunk.x}px`,
          top: `${elements.pseudoPhunk.y}px`,
          width: `${elements.pseudoPhunk.width}px`,
          height: `${elements.pseudoPhunk.height}px`,
        }}
        draggable="true"
        onDragStart={(e) => handleDragStart(e, 'pseudoPhunk')}
      >
        <div className="gift-container">
          <div className="pseudo-container">
            <div className="pseudo">PSEUDO</div>
            <div className="gift-icon">🎁</div>
          </div>
        </div>
        <div
          className="resize-handle bottom-right"
          onMouseDown={(e) => handleResizeStart(e, 'pseudoPhunk')}
        />
      </div>

      {/* Pseudo Techno */}
      <div
        className="draggable"
        style={{
          left: `${elements.pseudoTechno.x}px`,
          top: `${elements.pseudoTechno.y}px`,
          width: `${elements.pseudoTechno.width}px`,
          height: `${elements.pseudoTechno.height}px`,
        }}
        draggable="true"
        onDragStart={(e) => handleDragStart(e, 'pseudoTechno')}
      >
        <div className="gift-container">
          <div className="pseudo-container">
            <div className="pseudo">PSEUDO</div>
            <div className="gift-icon">🎁</div>
          </div>
        </div>
        <div
          className="resize-handle bottom-right"
          onMouseDown={(e) => handleResizeStart(e, 'pseudoTechno')}
        />
      </div>

      {/* Jauge */}
      <div
        className="draggable"
        style={{
          left: `${elements.jauge.x}px`,
          top: `${elements.jauge.y}px`,
          width: `${elements.jauge.width}px`,
          height: `${elements.jauge.height}px`,
        }}
        draggable="true"
        onDragStart={(e) => handleDragStart(e, 'jauge')}
      >
        <div className="jauge-container" style={{ width: '100%' }}>
          <div className="jauge phunk" style={{ width: `${phunkPercentage}%` }}>
            <div className="percentage-label left">{phunkPercentage.toFixed(1)}%</div>
          </div>
          <div className="jauge techno" style={{ width: `${technoPercentage}%` }}>
            <div className="percentage-label right">{technoPercentage.toFixed(1)}%</div>
          </div>
        </div>
        <div
          className="resize-handle bottom-right"
          onMouseDown={(e) => handleResizeStart(e, 'jauge')}
        />
      </div>

      {/* Actions Phunk */}
      <div
        className="draggable"
        style={{
          left: `${elements.actionsPhunk.x}px`,
          top: `${elements.actionsPhunk.y}px`,
          width: `${elements.actionsPhunk.width}px`,
          height: `${elements.actionsPhunk.height}px`,
        }}
        draggable="true"
        onDragStart={(e) => handleDragStart(e, 'actionsPhunk')}
      >
        <div className="actions">
          <div className="action-item">
            <img src={RoseIcon} alt="Rose" className="icon" /> = 1
          </div>
          <div className="action-item">
            <img src={HeartIcon} alt="Heart" className="icon" /> = 0.5
          </div>
        </div>
        <div
          className="resize-handle bottom-right"
          onMouseDown={(e) => handleResizeStart(e, 'actionsPhunk')}
        />
      </div>

      {/* Actions Techno */}
      <div
        className="draggable"
        style={{
          left: `${elements.actionsTechno.x}px`,
          top: `${elements.actionsTechno.y}px`,
          width: `${elements.actionsTechno.width}px`,
          height: `${elements.actionsTechno.height}px`,
        }}
        draggable="true"
        onDragStart={(e) => handleDragStart(e, 'actionsTechno')}
      >
        <div className="actions">
          <div className="action-item">
            <img src={ChatIcon} alt="Chat Bubble" className="icon" /> = 0.5
          </div>
          <div className="action-item">
            <img src={MusicNoteIcon} alt="Music Note" className="icon" /> = 1
          </div>
        </div>
        <div
          className="resize-handle bottom-right"
          onMouseDown={(e) => handleResizeStart(e, 'actionsTechno')}
        />
      </div>
    </div>
  );
}

export default Preset2;
