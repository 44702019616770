import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Home from './components/js/Home';  // Assurez-vous que le chemin est correct
import App from './components/js/Game';
import Preset2 from './components/js/Preset2';    // Assurez-vous que le chemin est correct
import Settings from './components/js/Settings';  // Assurez-vous que le chemin est correct
import Credit from './components/js/Credit';  // Assurez-vous que le chemin est correct
import Retour from './components/js/Feedback';  // Assurez-vous que le chemin est correct

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/game" element={<App />} />
        <Route path="/preset2" element={<Preset2 />} />
        <Route path="/settings" element={<Settings />} />  {/* Nouvelle route pour les paramètres */}
        <Route path="/credit" element={<Credit />} />  {/* Nouvelle route pour les paramètres */}
        <Route path="/feedback" element={<Retour />} />  {/* Nouvelle route pour les paramètres */}
      </Routes>
    </Router>
  </React.StrictMode>
);
